<template>
  <div>
    <page-header :name="title">
      <b-button @click="onOpenImportModal">
        Importar Sorteios
      </b-button>
    </page-header>
    <b-tabs>
      <b-tab title="Informações">
        <b-card>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
            />
            <b-row class="pt-1 align-items-center">
              <b-col sm="12" md="5">
                <i2-form-input v-model="promotion.settings.hotsite_url" type="url"
                               label="Url do HotSite"
                               rules="url"
                />
              </b-col>
              <b-col sm="12" md="5">
                <i2-form-input-file v-model="promotion.settings.hotsite_screenshot" accept="image/*"
                                    label="Screenshot do site"
                                    @change="showPreview"
                />
              </b-col>
              <b-col class="pt-1" sm="12" md="2">
                <b-form-input
                  v-model="promotion.settings.background_color"
                  class="input-color"
                  type="color"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col offset-md="5">
                <img v-if="promotion.hotsite_screenshot" id="screenshot-preview" :src="promotion.hotsite_screenshot"
                     height="170" alt="imagem da promoção"
                >
                <i2-icon-button v-if="promotion.settings.hotsite_screenshot" icon="TrashIcon"
                                @click="clearScreenshot"
                />
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col sm="12" md="12">
                <i2-form-input v-model="promotion.settings.timeline_url" type="url"
                               label="Url da TimeLine da promoção"
                               rules="url"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <i2-form-input-file v-model="promotion.integration_config" accept="application/json"
                                    label="Configuraçao da integração"
                />
              </b-col>
              <b-col sm="2" class="mt-1" align-self="center">
                <b-button-group justify>
                  <i2-icon-button title="Ver configuração de Integração" icon="EyeIcon" variant="primary" class="align-middle" @click="openIntegrationConfig"/>
                  <i2-icon-button title="Remover configuração de Integração" icon="TrashIcon" variant="danger" class="align-middle" @click="deleteIntegrationConfig"/>
                </b-button-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <i2-form-vue-select
                  v-model="managers"
                  :multiple="true"
                  :options="userList"
                  name="userList"
                  label="Responsáveis"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" md="4">
                <i2-form-input v-model="promotion.settings.phase"
                               name="phase"
                               label="Fase/Etapa"
                />
              </b-col>
              <b-col sm="6" md="4">
                <i2-form-vue-select
                  v-model="promotion.accountability"
                  :options="accountabilityTypes"
                  label="Geração do Arquivo de Prestação de Contas"
                  name="accountability"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button variant="primary" @click="onSave">
                  Salvar
                </b-button>
              </b-col>
              <b-row/>
            </b-row>

          </validation-observer>
        </b-card>
      </b-tab>
      <b-tab title="Empresas Participantes">
        <tab-company-available :promotion="promotion"/>
      </b-tab>
      <b-tab title="Webhooks">
        <tab-promotion-weeb-hook :promotion="promotion"/>
      </b-tab>
    </b-tabs>
    <b-modal
      v-model="showModalImport"
      title="Importação de Sorteios"
      ok-title="Salvar"
      centered
      cancel-title="Fechar"
      no-close-on-backdrop
      @ok="importFile"
    >
      <b-card-text>
        Selecione o arquivo deseja Importar. <b>Todos</b> os dados de sorteios, segmentos e premiação serão
        <b>Excluídos</b>
      </b-card-text>
      <b-form-file
        v-model="file"
        required
        placeholder="Informe aqui um arquivo."
        drop-placeholder="Arraste um arquivo para cá"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </b-modal>
    <promotion-integration-config-modal :uuid-promotion="currentPromotionUuid" :is-open.sync="showModalIntegrationConfig" @onClose="closeIntegrationConfig" />
  </div>
</template>

<script>
// eslint-disable-next-line
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import TabCompanyAvailable from '@/views/promotions/TabCompanyAvailable'
import TabPromotionWeebHook from '@/views/promotions/TabPromotionWeebHook.vue'

import AccountabilityTypes from '@/variables/AccountabilityTypes'
import PromotionIntegrationConfigModal from '@/views/promotions/PromotionIntegrationConfigModal.vue'

export default {
  name: 'PromotionDetails',
  components: {
    PromotionIntegrationConfigModal,
    TabPromotionWeebHook,
    TabCompanyAvailable,
    I2FormInputFile,
    ValidationObserver,
  },
  data() {
    return {
      showModalImport: false,
      showModalIntegrationConfig: false,
      managers: [],
      promotion: {
        name: '',
        hotsite_screenshot_url: '',
        showModalImport: false,
        integration_config: null,
        accountability: '',
        settings: {
          hotsite_url: '',
          hotsite_screenshot: '',
          timeline_url: '',
          phase: '',
          background_color: '#FFFFFF',
        },
      },
      file: null,
      accountabilityTypes: AccountabilityTypes,
      userList: [],
      title: '',
      snowOption: {
        theme: 'snow',
      },
      content: '',
      currentPromotionUuid: '',
    }
  },
  async created() {
    const uuid = this.$route.params.uuid || ''
    this.currentPromotionUuid = uuid
    await this.getUsers()
    await this.getData(uuid)
  },
  methods: {
    async getData(uuid) {
      const response = await this.$http.get(`promotions/${uuid}?include=managers`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      this.promotion = response
      this.managers = response.managers.map(user => ({
        text: user.name,
        value: user.uuid,
      }))
      this.title = `Outras informações de ${this.promotion.name}`

      if (!this.promotion.accountability_type) {
        this.promotion.accountability = this.accountabilityTypes[0]
      } else {
        this.promotion.accountability = this.accountabilityTypes.find(type => type.value === this.promotion.accountability_type)
      }
    },
    async onSave() {
      const data = new FormData()
      const managers = this.managers.map(item => item.value)

      data.append('managers', managers)
      data.append('hotsite_url', this.promotion.settings.hotsite_url)
      data.append('timeline_url', this.promotion.settings.timeline_url)
      data.append('background_color', this.promotion.settings.background_color)
      data.append('phase', this.promotion.settings.phase)
      data.append('accountability_type', this.promotion.accountability.value)
      data.append('_method', 'put')
      if (this.promotion.settings.hotsite_screenshot !== '') {
        data.append('hotsite_screenshot', this.promotion.settings.hotsite_screenshot)
      }
      if (this.promotion.integration_config !== '') {
        data.append('integration_config', this.promotion.integration_config)
      }

      const response = await this.$http.post(`promotions/${this.promotion.uuid}/settings`, data)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Os dados da promoção foram salvos.')
    },
    showPreview(event) {
      if (event.target.files.length > 0) {
        const src = URL.createObjectURL(event.target.files[0])
        const preview = document.getElementById('screenshot-preview')
        preview.src = src
      }
    },
    clearScreenshot() {
      this.promotion.settings.hotsite_screenshot = ''
      const preview = document.getElementById('screenshot-preview')
      preview.src = `${process.env.VUE_APP_URL}/storage/screenshots/default-screenshot.png`
    },
    async importFile(event) {
      event.preventDefault()
      if (!this.file) {
        this.$notify.error('Informe um arquivo!')
        return
      }
      const formData = new FormData()
      formData.append('file', this.file)
      const response = await this.$http.post(`promotions/${this.promotion.uuid}/import-prize-draws`, formData)

      if (response.errors > 0) {
        this.$notify.error('Não possível importar o arquivo.')
        return
      }
      this.$notify.success('Arquivo importado com sucesso')

      this.showModalImport = false
      this.$router.push('/prize-draw')
    },
    onOpenImportModal() {
      this.showModalImport = true
    },
    async getUsers() {
      const response = await this.$http.get('users')
      this.userList = []
      this.userList = response.map(item => ({
        text: item.name,
        value: item.uuid,
      }))
    },
    openIntegrationConfig() {
      this.showModalIntegrationConfig = true
    },
    closeIntegrationConfig() {
      this.showModalIntegrationConfig = false
    },
    deleteIntegrationConfig() {
      this.$apiDelete.execute(
        `/promotions/${this.promotion.uuid}/load-settings`,
        'Confirma a exclusão da configuração de integração?',
        'Excluir Configuração',
        () => {
          this.$notify.success('Configuração excluída com sucesso.')
        },
      )
    },
  },

}
</script>

<style scoped>
.input-color {
  width: 50px !important;
}
</style>
